<template>
  <div class="app">
    <AppHeader fixed style="display:flex; flex-direction:row;">
      <!-- <SidebarToggler class="d-lg-none" display="md" mobile/> -->
      <div class="navbar-brand" style="display:flex; flex-direction:column: width:10%;">
        <img
          class="navbar-brand-full"
          src="img/joann-logo.png"
          width="90"
          style="padding-left:0px"
          alt="Logo"
        >
        <img class="navbar-brand-minimized" src="img/joann-logo.png" width="230"
         style="padding-left:130px" alt="Logo">
      </div>
      <div align="middle" style="display:flex; flex-direction:column; width: 80%">
        <img
          class="navbar-brand-full"
          src="img/Allocate$mart.png"
          width="150"
          alt="Logo"
        >
      </div>
      <SidebarToggler id="toggle1" class="d-md-down-none"/>
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="d-md-down-none">
          <i
            class="icon-power icons font-2xl d-block mt-2"
            style="color: #c71585"
            @click="logout()"
          ></i>
        </b-nav-item>
      </b-navbar-nav> 
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
      </AppAside>
    </div>
  </div>
</template>

<script>
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import router from '../router'
import Vue from 'vue';
import VueGtag from 'vue-gtag'
console.log("Users")
export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data() {
    return {
      nav: nav.items,
      dialogOptions: {
        cancelText: "Cancel",
        animation: "bounce",
        backdropClose: true
      }
    };
  },
  mounted(){
    console.log("Mounted",process.env.VUE_APP_TRACKING_ID)
    Vue.use(VueGtag, {
          pageTrackerScreenviewEnabled: true,
          config: { 
            // id: 'UA-188441903-1',
            id: process.env.VUE_APP_TRACKING_ID,
            params: {
              user_id: window.localStorage.getItem('emailHash')
            }
          },
        },router)
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    }
  },
  methods: {
    logout() {
      this.$dialog
        .confirm("Are you sure you want to Logout?", this.dialogOptions)
        .then(() => {
          window.localStorage.removeItem('userName')
          window.localStorage.removeItem('token')
          window.localStorage.removeItem('emailId')
          window.localStorage.removeItem('status')
          window.localStorage.removeItem('name')
          window.localStorage.removeItem('siteData')
          window.localStorage.removeItem('loggedin')
          window.localStorage.removeItem('dataUpdateInfo')
          window.localStorage.removeItem('emailHash')
          
          this.$router.push("/");
        })
        .catch(function() {
          reject();
        });
    }
  }
};
</script>
<style>
.breadcrumb {
  background-color: white;
  margin-bottom: 0.25rem !important;
}
/* .nav{
  box-shadow:5px 5px 5px #999 !important
} */
.sidebar .nav-link .nav-icon {
  color: #75bf00 !important;
  font-size: 1.25rem !important;
  vertical-align: text-bottom;
}
.sidebar .nav-link {
  color: #000 !important;
}
.sidebar .nav-dropdown.open {
  background: white !important;
}
.sidebar .nav-dropdown.open .nav-link {
  color: black !important;
  border-left: 0;
}
.nav-item nav-dropdown {
  color: black !important;
}
.app-header {
  background-color: white !important;
}
.navbar-toggler-icon {
  visibility: none !important;
}
/* .sidebar{
  box-shadow:1px 1px px #999 !important
} */
.sidebar-minimized .sidebar .sidebar-minimizer {
  position: fixed;
  bottom: 0;
  width: 50px;
  height: 50px;
  background-color: white !important;
  color: black !important;
}
.sidebar .sidebar-minimizer {
  background-color: white;
}
.sidebar .sidebar-minimizer:hover {
  background-color: #f7f7f7 !important;
}
.sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
  background: #f7f7f7 !important;
}
.sidebar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  color: black !important;
  background: white !important;
}
.sidebar .nav-link:hover {
  color: black !important;
  background: #f7f7f7 !important;
}
.sidebar .nav-link.active {
  color: black !important;
  background: #f7f7f7;
}
.material-icons.attach_money::after {
  content: "attach_money";
}
.application a {
  color: #73bd00;
}
.material-icons.shopping_basket::after {
  content: "shopping_basket";
}

.material-icons.store::after {
  content: "store";
}

.material-icons.location_on::after {
  content: "location_on";
}

.material-icons.date_range::after {
  content: "date_range";
}

.material-icons.tv::after {
  content: "tv";
}

.material-icons.aspect_ratio::after {
  content: "aspect_ratio";
}

.material-icons.trending_down::after {
  content: "trending_down";
}
.nav-link[data-v-6c5c85a4] {
  cursor: pointer;
  color: black;
}
.main {
  background-color: #f4f6f8 !important;
}
.abc {
  font-weight: 500 !important;
  margin-left: 450px;
  font-size: 30px !important;
  padding-top: 8px;
}
.navbar-toggler-icon {
  display: none !important;
}
button#toggle1 {
  display: none;
}
/* .container {
    max-width: 100% !important;
} */
</style>
