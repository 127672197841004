export default {
  items: [
    {
      name: 'Dashboard',
      icon: 'fa fa-tachometer fa-4x',
      url: '/dashboard'
    },
    {
      name: 'Create Allocation',
      icon: 'fa fa-plus-square-o fa-4x',
      url: '/createAllocation/'
    },
    {
      name: 'Settings',
      icon: 'fa fa-cogs fa-4x',
      url: '/settings'
    },
    {
      name: 'Reset Password',
      icon: 'fa fa-key fa-4x',
      url: '/resetPassword'
    },
    {
      name: 'Raise Ticket',
      icon: 'fa fa-question-circle-o fa-4x',
      url: '/ticketGenertate'
    },
  ]
}
